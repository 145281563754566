import { useCreateIframeMutation } from 'graphql/mutations/iframe/generated/CreateIframe';
import { IframeFormProps, TInitialValues } from './IframeForm.types';
import { IframeInput, IntegrationService, ProjectType, TemplateName } from 'graphql/types.generated';
import { keys } from 'lodash';
import * as yup from 'yup';
import { setApolloFormikError } from 'components/common/Form';
import { generatePath, useNavigate } from 'react-router-dom';
import { routePaths } from 'router/routes';
import { useUpdateIframeMutation } from 'graphql/mutations/iframe/generated/UpdateIframe';
import { useCallback } from 'react';

export const getInitialValues = (iframe?: IframeFormProps['iframe']): TInitialValues => {
  return {
    name: iframe?.name || '',
    title: iframe?.title || '',
    contactEmail: iframe?.contactEmail || '',
    domainWhiteListTypes: iframe?.domainWhiteListTypes || [],
    headInjectionTags: iframe?.headInjectionTags || [],
    privacyPolicyUrl: iframe?.privacyPolicyUrl || null,
    termsOfUseUrl: iframe?.termsOfUseUrl || null,
    theme: {
      pdfButtonColor: iframe?.theme?.pdfButtonColor || iframe?.theme?.primaryColor || '#000000',
      primaryColor: iframe?.theme?.primaryColor || '#000000',
      textColor: iframe?.theme?.textColor || '#000000',
    },
    requestProjectPdfConfig: {
      emailFrom: iframe?.requestProjectPdfConfig?.emailFrom || '',
      subject: iframe?.requestProjectPdfConfig?.subject || '',
      message: iframe?.requestProjectPdfConfig?.message || '',
      useImplementationStartPoint: iframe?.requestProjectPdfConfig?.useImplementationStartPoint || false,
      usePhoneNumber: iframe?.requestProjectPdfConfig?.usePhoneNumber || false,
      newPdfRequestNotification: iframe?.requestProjectPdfConfig?.newPdfRequestNotification || false,
    },
    projectPdfConfig: {
      pdfWebsiteUrl: iframe?.projectPdfConfig?.pdfWebsiteUrl || '',
      pdfDownloadEnabledViaPublicRoute: iframe?.projectPdfConfig?.pdfDownloadEnabledViaPublicRoute || false,
    },
    projectType: iframe?.projectType || null,
    template: iframe?.template || null,
    country: iframe?.country || 'DE',
    integrationServices: iframe?.integrationServices || [],
  };
};

export const getTemplatesByProjectType = (projectType?: ProjectType | null) => {
  if (!projectType) return [];
  const mapping = {
    [ProjectType.renovatingHouse]: [
      TemplateName.eoxh,
      TemplateName.uhg,
      TemplateName.phlqedx,
      TemplateName.hiil,
      TemplateName.vdqlhuxqjvuhfkqhuDW,
      TemplateName.vsdundvvhErghqvhh,
      TemplateName.vsdundvvhGxuhq,
      TemplateName.vsdundvvhPrqfkhqjodgedfk,
      TemplateName.kbsoQrh,
      TemplateName.hiilIxoo,
      TemplateName.rehuedqn,
      TemplateName.default,
    ],
    [ProjectType.newBuilding]: [
      TemplateName.rehuedqn,
      TemplateName.phlqedx,
      TemplateName.kbsoQrh,
      TemplateName.default,
    ],
    [ProjectType.multiFamilyHouse]: [
      TemplateName.rehuedqn,
      TemplateName.phlqedx,
      TemplateName.vdqlhuxqjvuhfkqhuDW,
      TemplateName.default,
    ],
  };
  return mapping[projectType];
};

export const getFormOptions = ({ templateOptions }: { templateOptions: TemplateName[] }) => {
  const projectTypes = keys(ProjectType).map((key) => ({ value: key, label: `projectTypes.${key}` }));
  const integrationServices = keys(IntegrationService).map((key) => ({
    value: key,
    label: `iframe.form.integrationServiceOptions.${key}`,
  }));
  const countries = [
    { value: 'DE', label: 'iframe.countries.de' },
    { value: 'AT', label: 'iframe.countries.at' },
  ];
  const templates = templateOptions.map((key) => ({
    value: key,
    label: `iframe.templates.${key}`,
  }));

  return { projectTypes, countries, templates, integrationServices };
};

export const validationSchema = yup.object().shape({
  name: yup.string().required('errors.requiredField'),
  title: yup.string().required('errors.requiredField'),
  contactEmail: yup.string().email('errors.invalidEmail').required('errors.requiredField'),
  requestProjectPdfConfig: yup.object().shape({
    emailFrom: yup.string().required('errors.requiredField'),
    subject: yup.string().required('errors.requiredField'),
    message: yup.string().required('errors.requiredField'),
  }),
  projectPdfConfig: yup.object().shape({
    pdfWebsiteUrl: yup.string().url('errors.invalidUrl').required('errors.requiredField'),
  }),
  privacyPolicyUrl: yup.string().url('errors.invalidUrl'),
  termsOfUseUrl: yup.string().url('errors.invalidUrl'),
  projectType: yup.string().oneOf(Object.values(ProjectType)).required('errors.requiredField'),
  template: yup.string().oneOf(Object.values(TemplateName)).required('errors.requiredField'),
});

export const useSubmitIframeForm = ({ iframeId, tenantId }: { iframeId?: string; tenantId: string }) => {
  const navigate = useNavigate();

  const [createIframeMutation] = useCreateIframeMutation({
    onError: setApolloFormikError,
    onCompleted: async ({ createIframe }) => {
      navigate(
        generatePath(routePaths.tenantIframe, {
          iframeId: createIframe._id,
          tenantId: createIframe.tenantId,
        }),
      );
    },
  });
  const [updateIframeMutation] = useUpdateIframeMutation({
    onError: setApolloFormikError,
    onCompleted: async ({ updateIframe }) => {
      navigate(
        generatePath(routePaths.tenantIframe, {
          iframeId: updateIframe._id,
          tenantId: updateIframe.tenantId,
        }),
      );
    },
  });

  const onSubmit = useCallback(
    async (values: TInitialValues) => {
      if (iframeId) {
        await updateIframeMutation({ variables: { iframeId, formData: values as IframeInput } });
      } else {
        await createIframeMutation({ variables: { tenantId, formData: values as IframeInput } });
      }
    },
    [createIframeMutation, iframeId, tenantId, updateIframeMutation],
  );

  return onSubmit;
};
